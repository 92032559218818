import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appCamelCase]'
})
export class CamelCaseDirective {

  private el: HTMLInputElement;
  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener("keyup", ["$event.target.value"])
  keyup(value) {
    if (value != "") {
      var inputVal = value[0].toUpperCase() + value.slice(1);
      this.el.value = inputVal;
    }
  }

}
