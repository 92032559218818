import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor() { }

  /* Set Patterns */
  getPatterns() {
    var isPatterns = {
      alpha: "[a-zA-Z ]*",
      alphaNumeric: "[a-zA-Z0-9 ]*",
      alphaNumericSpl: "[a-zA-Z0-9 |-]*",
      alphaSpl: "[a-zA-Z |-]*",
      numeric: "[0-9 ]{13}$",
      // mobileNo: "^[0-9]{5,25}$",
      // mobileNo: "^\+?[\d\s]+$",
      // mobileNo: "[0-9\s]*",
     
    mobileNo: "^[+]*[(]{0,1}[0-9 ]{1,25}[)]{0,1}$",
      numericSpl: "[0-9 |-]*",
      email: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$"
    };
    return isPatterns;
  }
}
