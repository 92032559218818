import {
  Component,
  TemplateRef,
  ViewChild,
  Inject,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  Input,
  ElementRef,
} from "@angular/core";
import { BsModalService, BsModalRef } from "ngx-bootstrap/modal";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, from } from "rxjs";
import { AppComponent } from "../app.component";
import { Router, ActivatedRoute } from "@angular/router";
import { FormFieldIDService } from "../services/form-field-id.service";
import { ToastrService } from "ngx-toastr";
import "core-js";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

@Component({
  selector: "app-client-qst-form",
  templateUrl: "./client-qst-form.component.html",
  styleUrls: ["./client-qst-form.component.scss"],
})
export class ClientQstFormComponent implements OnInit {
  @ViewChild("template", { static: false })
  public templateref: TemplateRef<any>;
  @ViewChild("investmentSummary", { static: false })
  public investmentSummary: TemplateRef<any>;
  modalRef: BsModalRef;
  title = "questionnaireForm";
  formOpenModal: boolean = false;
  customFormData: any = {};
  riskPlacement: number = 0;
  EquivalentPerc: number = 0;
  responsivenesResult: string = "";
  refCode: string = "";
  incomeError: boolean = false;
  capital_Yeild: any = 6;
  rm_Name: any = "";
  quesIndex: number = 0;
  invstSmryObj: any = {
    riskProfile: { responsiveness: "" },
  };
  riskValidations: any = [];
  resValidations: any = [];
  totalAmountofLiquidty: any;
  userData: any = {};

  avaliableAmt: any = {
    val: 0,
    isNeg: true,
    liquidityNeedVal: 0,
    liquidityNeedCheck: false,
    totalWealthPerc: 100,
  };

  errorMsg: any = "";
  modalConfig = {
    class: "modal-lg summaryModal",
    backdrop: true,
    ignoreBackdropClick: true,
  };
  // attitudinalData:any = {};
  sendData: any = {};

  constructor(
    private modalService: BsModalService,
    private http: HttpClient,
    public app: AppComponent,
    public route: ActivatedRoute,
    public router: Router,
    public formFieldID: FormFieldIDService,
    private toastr: ToastrService
  ) {
    /* var isDate = new Date();
    isDate.setFullYear(isDate.getFullYear() - 18);
    this.sendData.dob = isDate; */
    /*this.sendData.firstname = "";
    this.sendData.lastname = "";
    this.sendData.email = "";
    this.sendData.gender = "";
    this.sendData.region = "";
    this.sendData.isRegion = "";*/
    am4core.useTheme(am4themes_animated);
  }

  maxDate: any = new Date();

  ngOnInit() {
    setTimeout(() => {
      this.checkToken();
    }, 200);
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, this.modalConfig);
  }

  /* Modal Close */
  closeModal(template: TemplateRef<any>) {
    this.modalRef.hide();
  }

  /* Check Token */
  checkToken() {
    var dataResponse;
    this.route.queryParamMap.subscribe((queryParams) => {
      this.refCode = queryParams.get("id");
      if (!this.refCode) {
        this.router.navigate(["formNotFound"]);
      }
    });
    if (this.refCode) {
      this.app.getLoader(true);
      var sendData = {
        token: this.refCode,
        type: "client",
      };
      var isHttpReq = this.http
        .post(this.app.rootURL + "checkToken", sendData)
        .subscribe((response) => {
          this.app.getLoader(false);
          dataResponse = response;
          if (dataResponse.success == true) {
            this.capital_Yeild = dataResponse.CY_Percentage;
            this.userData = dataResponse.data;
            // this.sendData.firstname =
            //   this.userData.first_name != null ? this.userData.first_name : "";
            // this.sendData.middlename = this.userData.middle_name;
            // this.sendData.lastname =
            //   this.userData.last_name != null ? this.userData.last_name : "";
            // this.sendData.dob =
            //   this.userData.dob != "" && this.userData.dob != null
            //     ? new Date(this.userData.dob)
            //     : "";
            //console.log('this.sendData.email', this.sendData.email);
            // this.sendData.email =
            //   this.userData.email_id != null ? this.userData.email_id : "";
            this.sendData.clientid =
              this.userData.client_id != null ? this.userData.client_id : "";
            // this.sendData.contact = this.userData.contact_no;
            // this.sendData.region =
            //   this.userData.region != null ? this.userData.region : "";
            // this.sendData.totalAum =
            //   this.userData.amount != null ? this.userData.amount : "";
            // //console.log(this.userData);
            // this.sendData.gender =
            //   this.userData.gender != null ? this.userData.gender : "";
            //console.log(this.userData);
            this.rm_Name = dataResponse.rm_Name;
            setTimeout(() => {
              this.openModal(this.templateref);
            }, 3000);
          } else {
            this.router.navigate(["formNotFound"]);
          }
        });
    }
  }

  /* Set Geo Asset Value */
  setGeoAsset(type, index) {
    var isType = type == "cg" ? "capitalGrowth" : "capitalYielding";
    var setStatus = this.questionnare[14].answer[isType][index].exclude;
    this.questionnare[14].answer[isType][index].exclude =
      setStatus == "yes" ? "no" : "yes";
    var count = 0;
    for (var g = 0; g < this.questionnare[14].answer[isType].length; g++) {
      if (this.questionnare[14].answer[isType][g].exclude == "yes") {
        count++;
      }
      if (this.questionnare[14].answer[isType].length - 1 == g) {
        if (this.questionnare[14].answer[isType].length == count) {
          this.questionnare[14].answer[isType][index].exclude = "no";
        }
      }
    }
  }

  /* Save Questionnaire Form */
  saveData(para) {
    var isSubmit = false;
    if (para == "summary") {
      isSubmit = true;
    } else {
      if (para.form.status == "VALID") {
        if (this.avaliableAmt.val != 0) {
          isSubmit = false;
        }
      }

      isSubmit = this.validateForm(para);
      let riskEle = this.attitudinalData.riskProfile;
      let resEle = this.attitudinalData.responsiveness;
      for (let i = 0; i < riskEle.length; i++) {
        if (riskEle[i].value == 0) {
          this.riskValidations[i] = "inValid";
          isSubmit = false;
        } else {
          this.riskValidations[i] = "";
        }
      }
      for (let i = 0; i < resEle.length; i++) {
        if (resEle[i].value == 0) {
          this.resValidations[i] = "inValid";
          isSubmit = false;
        } else {
          this.resValidations[i] = "";
        }
      }
    }

    if (isSubmit) {
      var questionnareData = JSON.stringify(this.questionnare);
      questionnareData = this.convertData(questionnareData); //JSON.parse(questionnareData);

      var dataResponse;
      var todayDate =
        new Date().getFullYear() +
        "-" +
        ("0" + (new Date().getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + new Date().getDate()).slice(-2);

      var isDob = "";
      var age: any = "";
      var dob = "";
      if (this.sendData.dob != "" && this.sendData.dob != undefined) {
        isDob =
          this.sendData.dob.getFullYear() +
          "-" +
          (this.sendData.dob.getMonth() + 1) +
          "-" +
          this.sendData.dob.getDate();

        dob = this.sendData.dob;
        var today = new Date();
        var birthDate = new Date(dob);
        age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
      }

      var sendData = {
        refID: this.refCode,
        type: "client",
        currentTotalAum: this.userData.amount,
        pageName: para != "summary" ? "form" : para,
        basic: {
          first_name: !this.sendData.firstname ? "" : this.sendData.firstname,
          last_name: !this.sendData.lastname ? "" : this.sendData.lastname,
          middle_name: !this.sendData.middlename
            ? ""
            : this.sendData.middlename,
          age: age == "" ? 0 : age,
          dob: isDob == "" ? null : isDob,
          gender: this.sendData.gender,
          location: this.sendData.region,
          emailId: this.sendData.email,
          contact_no: this.sendData.contact
            ? this.sendData.contact.replace(/[^\d,+]+/g, "")
            : "",
          questionnaireDate: todayDate,
          clientId: "",
          csv: "",
          app_status: "",
          user_id: "",
          privacy_note: this.sendData.privacynote,
        },
        questionnare: [],
        //questionnare: this.questionnare
      };

      sendData.questionnare.push(questionnareData)[0];
      if (this.investmentAmt < this.app.credDataObj.checkAmount) {
        var setData = {
          question_no: 16,
          answer: null,
        };
        sendData.questionnare[0][14] = setData;
      }
      sendData.questionnare = sendData.questionnare[0];

      //console.log("sendData", sendData);
      this.app.getLoader(true);
      this.http.post(this.app.rootURL + "getUserData", sendData).subscribe(
        (response) => {
          dataResponse = response;
          this.app.getLoader(false);
          if (dataResponse.success) {
            if (para != "summary") {
              this.invstSmryObj = dataResponse.data;
              var riskProfile = this.invstSmryObj[this.invstSmryObj.length - 1];
              this.invstSmryObj.riskProfile = riskProfile;
              this.openModal(this.investmentSummary);
              setTimeout(() => {
                this.startRenderResChart();
              }, 200);
            } else {
              this.router.navigate(["thankYou"]);
            }
          } else {
            this.toastr.error("Error: " + dataResponse.msg, "", {
              timeOut: 3000,
            });
          }
        },
        (error) => {
          this.app.getLoader(false);
        }
      );
    } else {
      this.toastr.error("Please fill all the required fields", "", {
        timeOut: 3000,
      });
      //this.validateForm(para);
    }
  }

  /* Form Validation And Scroll */
  validateForm(form) {
    var isValidInput = true;

    // if (
    //   (this.questionnare[0].answer == "" || this.questionnare[0].answer == 0) &&
    //   isValidInput
    // ) {
    //   //Investment Amount (Q1)
    //   isValidInput = false;
    //   var isElement = document.getElementById("investmentAmt");
    //   isElement.scrollIntoView();
    // }

    if (this.questionnare[0].answer == 0 && isValidInput) {
      //Investment Amount (Q1)
      this.questionnare[1].answer[0].immediately = 0;
      this.questionnare[1].answer[0].year1 = 0;
      this.questionnare[1].answer[0].year2 = 0;
      this.questionnare[1].answer[0].above2Years = 0;
      this.avaliableAmt.val = 0;
    }
    if (this.questionnare[0].answer != "" && this.questionnare[0].answer != 0) {
      if (
        (this.questionnare[1].answer[0].immediately == "" ||
          this.questionnare[1].answer[0].immediately == 0) &&
        isValidInput
      ) {
        //Scheduling Immediate Amount (Q2)
        isValidInput = false;
        var isElement = document.getElementById("immediateamt");
        isElement.scrollIntoView();
      } else {
        if (
          this.avaliableAmt.val < 0 &&
          this.questionnare[1].answer[0].immediately != "" &&
          isValidInput
        ) {
          isValidInput = false;
          var isElement = document.getElementById("immediateamt");
          isElement.scrollIntoView();
        }

        if (
          this.avaliableAmt.val != 0 &&
          this.questionnare[1].answer[0].immediately != "" &&
          isValidInput
        ) {
          isValidInput = false;
          var isElement = document.getElementById("immediateamt");
          isElement.scrollIntoView();
        }
      }
    }

    if (
      this.questionnare[2].answer == "" &&
      isValidInput &&
      this.questionnare[2].others == ""
    ) {
      //Investment Behalf of (Q3)
      isValidInput = false;
      var isElement = document.getElementById("behalfInvest");
      isElement.scrollIntoView();
    }

    if (this.questionnare[4].answer == "" && isValidInput) {
      //Ethical Investment (Q5)
      isValidInput = false;
      var isElement = document.getElementById("ethicalInvestment");
      isElement.scrollIntoView();
    }

    if (this.questionnare[5].answer == "" && isValidInput) {
      // Investment Complaint (Q6)
      isValidInput = false;
      var isElement = document.getElementById("investComplaint");
      isElement.scrollIntoView();
    }

    if (this.questionnare[6].answer == "" && isValidInput) {
      //Regular Income (Q7)
      isValidInput = false;
      var isElement = document.getElementById("regularIncome");
      isElement.scrollIntoView();
    } else {
      if (
        this.questionnare[6].answer == "Yes" &&
        (this.questionnare[7].answer == "" ||
          this.questionnare[7].answer == 0) &&
        isValidInput
      ) {
        isValidInput = false;
        var isElement = document.getElementById("regularIncome");
        isElement.scrollIntoView();
      } else {
        if (this.capital_Yeild < this.incomePerc) {
          isValidInput = false;
          var isElement = document.getElementById("regularIncome");
          isElement.scrollIntoView();
        }
      }
    }

    if (this.questionnare[8].answer[0].liquidity == "" && isValidInput) {
      //Withdrawals Needs (Q8)
      isValidInput = false;
      var isElement = document.getElementById("withdrawalNeeds");
      isElement.scrollIntoView();
    } else {
      if (this.questionnare[8].answer[0].liquidity == "Yes" && isValidInput)
        if (
          (this.questionnare[8].answer[0].year1 == "" ||
            this.questionnare[8].answer[0].year1 == 0) &&
          (this.questionnare[8].answer[0].year2 == "" ||
            this.questionnare[8].answer[0].year2 == 0) &&
          (this.questionnare[8].answer[0].year3 == "" ||
            this.questionnare[8].answer[0].year3 == 0)
        ) {
          isValidInput = false;
          var isElement = document.getElementById("withdrawalNeeds");
          isElement.scrollIntoView();
        }
      if (this.avaliableAmt.liquidityNeedCheck == true) {
        isValidInput = false;
        var isElement = document.getElementById("withdrawalNeeds");
        isElement.scrollIntoView();
      }
    }

    if (this.questionnare[9].answer == "" && isValidInput) {
      //Portfolio Objectives (Q10)
      isValidInput = false;
      var isElement = document.getElementById("portfolioObjectives");
      isElement.scrollIntoView();
    }

    if (this.questionnare[10].answer != "" && isValidInput) {
      //Portfolio Objectives (Q10)
      if (this.wealthError && isValidInput) {
        isValidInput = false;
        var isElement = document.getElementById("netwealth");
        isElement.scrollIntoView();
      }
      if (this.questionnare[11].answer > 100) {
        isValidInput = false;
        var isElement = document.getElementById("internationalnetwealth");
        isElement.scrollIntoView();
      }
    }

    if (isValidInput) {
      let riskEle = this.attitudinalData.riskProfile;
      let resEle = this.attitudinalData.responsiveness;
      var riskResponseData = this.formFieldID.getFormIdList();
      for (let i = 0; i < riskEle.length; i++) {
        var inputRiskProfile = riskResponseData.riskProfile[i];
        var isElement = document.getElementById(inputRiskProfile);
        isElement.scrollIntoView();
        if (riskEle[i].value == 0) {
          isValidInput = false;
          break;
        }

        if (i == riskEle.length - 1 && isValidInput) {
          for (let i = 0; i < resEle.length; i++) {
            var inputResponsiveness = riskResponseData.responsiveness[i];
            var isElement = document.getElementById(inputResponsiveness);
            isElement.scrollIntoView();
            if (resEle[i].value == 0) {
              isValidInput = false;
              break;
            }
          }
        }
      }
    }
    return isValidInput;
  }

  startRenderResChart() {
    // create chart
    var chart = am4core.create("respnsnssChartView", am4charts.GaugeChart);
    chart.hiddenState.properties.opacity = 0; // this makes initial fade in effect

    chart.innerRadius = -45;
    chart.startAngle = 200;
    chart.endAngle = 340;
    chart.y = 0;
    chart.logo.disabled = true;

    var axis = chart.xAxes.push(
      new am4charts.ValueAxis<am4charts.AxisRendererCircular>()
    );
    axis.min = 0;
    axis.max = 3;
    axis.renderer.grid.template.disabled = true;
    axis.renderer.labels.template.disabled = true;
    axis.renderer.grid.template.strokeOpacity = 1;
    axis.renderer.grid.template.stroke = am4core.color("#1a1a1a");
    axis.renderer.grid.template.strokeWidth = 4;

    var range0 = axis.axisRanges.create();
    range0.value = 0;
    range0.endValue = 1;
    range0.axisFill.fillOpacity = 1;
    range0.axisFill.fill = am4core.color("#8eda5c");
    range0.axisFill.zIndex = -1;

    var range1 = axis.axisRanges.create();
    range1.value = 1;
    range1.endValue = 2;
    range1.axisFill.fillOpacity = 1;
    range1.axisFill.fill = am4core.color("#ffb914");
    range1.axisFill.zIndex = -1;

    var range2 = axis.axisRanges.create();
    range2.value = 2;
    range2.endValue = 3;
    range2.axisFill.fillOpacity = 1;
    range2.axisFill.fill = am4core.color("#ff7b6d");
    range2.axisFill.zIndex = -1;

    var hand = chart.hands.push(new am4charts.ClockHand());
    hand.fill = am4core.color("#fff");
    hand.stroke = am4core.color("#fff");
    hand.axis = axis;
    //hand.pin.disabled = true;
    hand.pin.radius = 5;
    hand.startWidth = 10;
    hand.endWidth = 0;
    hand.radius = am4core.percent(70);
    hand.innerRadius = am4core.percent(0);

    if (this.invstSmryObj.riskProfile.responsiveness.toLowerCase() == "low") {
      hand.showValue(0.5);
    } else if (
      this.invstSmryObj.riskProfile.responsiveness.toLowerCase() == "moderate"
    ) {
      hand.showValue(1.5);
    } else {
      hand.showValue(2.5);
    }
  }

  /* Convert Numbers Input */
  convertData(questionnareData) {
    var questionnare = JSON.parse(questionnareData);
    //Converting Investment Amount
    var isInvestmentAmt =
      questionnare[0].answer == ""
        ? ""
        : questionnare[0].answer.replace(/,/g, "");
    questionnare[0].answer =
      isInvestmentAmt == "" ? "" : parseInt(isInvestmentAmt);

    //Converting Investment Schedular Amount
    var immediateAmt =
      questionnare[1].answer[0].immediately == ""
        ? ""
        : questionnare[1].answer[0].immediately.replace(/,/g, "");
    questionnare[1].answer[0].immediately =
      immediateAmt == "" ? "" : parseInt(immediateAmt);

    var scheduleYear1 =
      questionnare[1].answer[0].year1 == ""
        ? ""
        : questionnare[1].answer[0].year1.replace(/,/g, "");
    questionnare[1].answer[0].year1 =
      scheduleYear1 == "" ? "" : parseInt(scheduleYear1);

    var scheduleYear2 =
      questionnare[1].answer[0].year2 == ""
        ? ""
        : questionnare[1].answer[0].year2.replace(/,/g, "");
    questionnare[1].answer[0].year2 =
      scheduleYear2 == "" ? "" : parseInt(scheduleYear2);

    var above2Years =
      questionnare[1].answer[0].above2Years == ""
        ? ""
        : questionnare[1].answer[0].above2Years.replace(/,/g, "");
    questionnare[1].answer[0].above2Years =
      above2Years == "" ? "" : parseInt(above2Years);

    //Converting Income Requirement Amount
    var incomeReqAmt =
      questionnare[7].answer == ""
        ? ""
        : questionnare[7].answer.replace(/,/g, "");
    questionnare[7].answer = incomeReqAmt == "" ? "" : parseInt(incomeReqAmt);

    //Converting Loquidity Needs Amount
    var year1LNeeds =
      questionnare[8].answer[0].year1 == ""
        ? ""
        : questionnare[8].answer[0].year1.replace(/,/g, "");
    questionnare[8].answer[0].year1 =
      year1LNeeds == "" ? "" : parseInt(year1LNeeds);

    var year2LNeeds =
      questionnare[8].answer[0].year2 == ""
        ? ""
        : questionnare[8].answer[0].year2.replace(/,/g, "");
    questionnare[8].answer[0].year2 =
      year2LNeeds == "" ? "" : parseInt(year2LNeeds);

    var year3LNeeds =
      questionnare[8].answer[0].year3 == ""
        ? ""
        : questionnare[8].answer[0].year3.replace(/,/g, "");
    questionnare[8].answer[0].year3 =
      year3LNeeds == "" ? "" : parseInt(year3LNeeds);

    //Assigning Onbehalf
    if (questionnare[2].answer == "") {
      questionnare[2].answer = questionnare[2].others;
    }

    //Assigning Appropriate Objectives
    if (questionnare[3].answer == "Other") {
      questionnare[3].answer = questionnare[3].others;
    }
    if (this.sendData.region == "Other") {
      this.sendData.region = this.sendData.isRegion;
    }

    /* Schedular Percentage */
    var investmentAmt = parseInt(questionnare[0].answer);
    questionnare[1].answer[0].immediatelyPerc =
      questionnare[1].answer[0].immediately != ""
        ? (
          (parseInt(questionnare[1].answer[0].immediately) / investmentAmt) *
          100
        ).toFixed(2)
        : "";

    questionnare[1].answer[0].year1Perc =
      questionnare[1].answer[0].year1 != ""
        ? (
          (parseInt(questionnare[1].answer[0].year1) / investmentAmt) *
          100
        ).toFixed(2)
        : "";

    questionnare[1].answer[0].year2Perc =
      questionnare[1].answer[0].year2 != ""
        ? (
          (parseInt(questionnare[1].answer[0].year2) / investmentAmt) *
          100
        ).toFixed(2)
        : "";

    questionnare[1].answer[0].above2YearsPerc =
      questionnare[1].answer[0].above2Years != ""
        ? (
          (parseInt(questionnare[1].answer[0].above2Years) / investmentAmt) *
          100
        ).toFixed(2)
        : "";

    return questionnare;
  }

  attitudinalData: any = {
    riskProfile: [
      {
        answer: "",
        value: 0,
        question: "I tend to avoid investment risks.",
      },
      {
        answer: "",
        value: 0,
        question:
          "It is more importent for me to invest safely then take risk for the Chance of higher return.",
      },
      {
        answer: "",
        value: 0,
        question: "I am more prepared to take investment risk than others.",
      },
      {
        answer: "",
        value: 0,
        question:
          "I am willing to Put a significant part of my wealth in high-risk investment.",
      },
      {
        answer: "",
        value: 0,
        question:
          "I am prepared to risk losing money if there is also a Chance to gain..",
      },
    ],
    responsiveness: [
      {
        answer: "",
        value: 0,
        question: " I tend to buy and sell investment more then I should .",
      },
      {
        answer: "",
        value: 0,
        question:
          "I have previously sold significant investment after sustained losses.",
      },
      {
        answer: "",
        value: 0,
        question: "I Tend to Review My Investment Frequently.",
      },
      {
        answer: "",
        value: 0,
        question: "Uncertainty makes me uneasy.",
      },
    ],
  };

  questionnare = [
    {
      question_no: 1,
      answer: "",
    },
    {
      question_no: 2,
      answer: [
        {
          immediately: "",
          year1: "",
          year2: "",
          above2Years: "",
          immediatelyPerc: "",
          year1Perc: "",
          year2Perc: "",
          above2YearsPerc: "",
        },
      ],
    },
    {
      question_no: 3,
      answer: "",
      others: "",
    },
    {
      question_no: 4,
      answer: "Growth",
      others: "",
    },
    {
      question_no: 5,
      answer: "",
    },
    {
      question_no: 6,
      answer: "",
    },
    {
      question_no: 7,
      answer: "",
    },
    {
      question_no: 8,
      answer: "",
    },
    {
      question_no: 9,
      answer: [
        {
          liquidity: "",
          year1: "",
          year2: "",
          year3: "",
          total_wealth: "",
        },
      ],
    },
    {
      question_no: 10,
      answer: "",
      desc: "",
    },
    {
      question_no: 11,
      answer: "",
    },
    {
      question_no: 12,
      answer: "",
    },
    {
      question_no: 13,
      answer: [
        {
          cash: 100,
          realestate: 0,
          privateequity: 0,
          fixedincome: 0,
          equities: 0,
        },
      ],
    },
    {
      question_no: 14,
      answer: this.attitudinalData,
    },
    {
      question_no: 16,
      answer: {
        capitalGrowth: [
          {
            fund: "US PE",
            exclude: "no",
          },
          {
            fund: "Asian PE",
            exclude: "no",
          },
          {
            fund: "Asian RE",
            exclude: "no",
          },
        ],
        capitalYielding: [
          {
            fund: "US RE Debt",
            exclude: "no",
          },
          {
            fund: "US Private Credit",
            exclude: "no",
          },
          {
            fund: "US RE",
            exclude: "no",
          },
        ],
      },
    },
    {
      question_no: 17,
      answer: "",
    },
    {
      question_no: 18,
      answer: "",
    },
  ];

  portfolioObjectives = [
    {
      title: "Indefinite",
      desc: "Inter-generational wealth",
    },
    {
      title: "Long Term",
      desc: "Three to seven years",
    },
    {
      title: "Short Term",
      desc: "Less than three years",
    },
  ];
  riskContradictQues = [0, 0, 0, 0, 0];

  // riskContradict(para, callback) {
  //   if (callback == "riskProfile") {
  //     if (para == 0) {
  //       if (
  //         Math.abs(this.riskContradictQues[1] - this.riskContradictQues[0]) >
  //           1 ||
  //         Math.abs(this.riskContradictQues[2] - this.riskContradictQues[0]) > 1
  //       ) {
  //         console.log(
  //           Math.abs(this.riskContradictQues[1] - this.riskContradictQues[0])
  //         );
  //         console.log(
  //           Math.abs(this.riskContradictQues[2] - this.riskContradictQues[0])
  //         );
  //         console.log("false q1");
  //       } else {
  //         console.log("true q1");
  //       }
  //     }
  //     if (para == 1) {
  //       if (
  //         Math.abs(this.riskContradictQues[2] - this.riskContradictQues[1]) >
  //           1 ||
  //         Math.abs(this.riskContradictQues[0] - this.riskContradictQues[1]) > 1
  //       ) {
  //         console.log("false q2");
  //       } else {
  //         console.log("true q2");
  //       }
  //     }
  //     if (para == 2) {
  //       if (
  //         Math.abs(this.riskContradictQues[0] - this.riskContradictQues[2]) >
  //           1 ||
  //         Math.abs(this.riskContradictQues[1] - this.riskContradictQues[2]) > 1
  //       ) {
  //         console.log("false q3");
  //       } else {
  //         console.log("true q3");
  //       }
  //     }
  //     if (para == 3) {
  //       if (
  //         Math.abs(this.riskContradictQues[0] - this.riskContradictQues[3]) >
  //           1 ||
  //         Math.abs(this.riskContradictQues[1] - this.riskContradictQues[3]) > 1
  //         ||
  //         Math.abs(this.riskContradictQues[2] - this.riskContradictQues[3]) > 1
  //       ) {
  //         console.log("false q4");
  //       } else {
  //         console.log("true q4");
  //       }
  //     }
  //     if (para == 4) {
  //       if (
  //         Math.abs(this.riskContradictQues[0] - this.riskContradictQues[4]) >
  //           1 ||
  //         Math.abs(this.riskContradictQues[1] - this.riskContradictQues[4]) > 1
  //         ||
  //         Math.abs(this.riskContradictQues[2] - this.riskContradictQues[4]) > 1
  //       ) {
  //         console.log("false q5");
  //       } else {
  //         console.log("true q5");
  //       }
  //     }
  //   }
  // }

  /* Calculate Risk Score */
  calcRiskScore(score, type, callback, index, quesIndex) {
    if (callback == "riskProfile") {
      this.riskValidations[index] = "";
      this.quesIndex = quesIndex;
      for (var r = 0; r < this.attitudinalData.riskProfile.length; r++) {
        this.attitudinalData.riskProfile[index].value = score;
        this.attitudinalData.riskProfile[index].answer = type;
      }
      this.riskContradictQues[index] = score;
      // console.log(this.riskContradictQues);
    } else {
      this.resValidations[index] = "";
    }
    // this.riskContradict(index, callback);
    if (callback == "responsiveness") {
      this.quesIndex = quesIndex;
      for (var r = 0; r < this.attitudinalData.responsiveness.length; r++) {
        this.attitudinalData.responsiveness[index].value = score;
        this.attitudinalData.responsiveness[index].answer = type;
      }
    }
    // console.log("this.isValidrisk1", this.isValidrisk1)
    // console.log("this.isValidrisk2", this.isValidrisk2)
  }

  /* Investment Schedular */
  warnMsg: any;
  isDisableYearOne: any;
  isDisableYearTwo: any;
  isDisableYearThree: any;
  isDisableYearAbove: any;
  investmentAmt: any = 0;
  immediatelyRound: any = 0;
  // roundImmediately(value) {
  //   var immediate = value.target.value.replace(/,/g, "");
  //   console.log(immediate);
  //   this.questionnare[1].answer[0].immediately =
  //     Math.round(immediate / 10000) * 10000;
  //   this.questionnare[1].answer[0].immediately = Number(
  //     this.questionnare[1].answer[0].immediately
  //   ).toLocaleString("en-GB");
  // }
  roundImmediately(element, para) {
    if (element.target.value) {
      if (para == "investment") {
        var investment = element.target.value.includes(',') ? element.target.value.replace(/,/g, "") : element.target.value;
        // console.log(investment);
        this.questionnare[0].answer = Math.round(investment / 10000) * 10000;
        this.questionnare[0].answer = Number(
          this.questionnare[0].answer
        ).toLocaleString("en-GB");
      } else if (para == "year1") {
        var year1 = element.target.value.includes(',') ? element.target.value.replace(/,/g, "") : element.target.value;
        // console.log(year1);
        this.questionnare[1].answer[0].year1 = Math.round(year1 / 10000) * 10000;
        this.questionnare[1].answer[0].year1 = Number(
          this.questionnare[1].answer[0].year1
        ).toLocaleString("en-GB");
      } else {
        var immediate = element.target.value.includes(',') ? element.target.value.replace(/,/g, "") : element.target.value;
        // console.log(immediate);
        this.questionnare[1].answer[0].immediately =
          Math.round(immediate / 10000) * 10000;
        this.questionnare[1].answer[0].immediately = Number(
          this.questionnare[1].answer[0].immediately
        ).toLocaleString("en-GB");
      }
    }
    this.investmentSchedular();
  }
  resetFundingZero() {
    if (this.questionnare[0].answer == 0) {
      //Investment Amount (Q1)
      this.questionnare[1].answer[0].immediately = "";
      this.questionnare[1].answer[0].year1 = "";
      this.questionnare[1].answer[0].year2 = "";
      this.questionnare[1].answer[0].above2Years = "";
      this.avaliableAmt.val = 0;
    }
  }
  investmentSchedular() {
    var investmentAmt =
      this.questionnare[0].answer == ""
        ? 0
        : this.questionnare[0].answer.replace(/,/g, "");
    if (parseInt(investmentAmt) > 0) {
      var immediately =
        this.questionnare[1].answer[0].immediately == ""
          ? 0
          : this.questionnare[1].answer[0].immediately.replace(/,/g, "");
      var yearOne =
        this.questionnare[1].answer[0].year1 == ""
          ? 0
          : this.questionnare[1].answer[0].year1.replace(/,/g, "");
      var yearTwo =
        this.questionnare[1].answer[0].year2 == ""
          ? 0
          : this.questionnare[1].answer[0].year2.replace(/,/g, "");

      var aboveThree =
        this.questionnare[1].answer[0].above2Years == ""
          ? 0
          : this.questionnare[1].answer[0].above2Years.replace(/,/g, "");

      var schedularAmt =
        parseInt(immediately) +
        parseInt(yearOne) +
        parseInt(yearTwo) +
        parseInt(aboveThree);
      this.avaliableAmt.val = investmentAmt - schedularAmt;

      if (Math.sign(this.avaliableAmt.val) == -1) {
        this.avaliableAmt.isNeg = true;
      } else {
        this.avaliableAmt.isNeg = false;
      }

      if (schedularAmt > investmentAmt) {
        if (investmentAmt == immediately) {
          this.questionnare[1].answer[0].year1 = "";
          this.questionnare[1].answer[0].year2 = "";
          this.questionnare[1].answer[0].above2Years = "";
        }
      }

      if (schedularAmt == investmentAmt) {
        this.getDisabledInput(true, "schedular");
      }
      if (schedularAmt < investmentAmt) {
        this.getDisabledInput(false, "schedular");
      }
      this.investmentAmt = investmentAmt;
      var geoAssets = ["capitalGrowth", "capitalYielding"];

      if (this.investmentAmt < this.app.credDataObj.checkAmount) {
        for (var i = 0; i < geoAssets.length; i++) {
          var isType = geoAssets[i];
          for (
            var g = 0;
            g < this.questionnare[14].answer[isType].length;
            g++
          ) {
            this.questionnare[14].answer[isType][g].exclude = "no";
          }
        }
      }
    } else {
      this.avaliableAmt.val = 0;
      this.toastr.info("Please enter Additional Funding", "", {
        timeOut: 3000,
      });
    }
  }

  /* Disabled Un wanted Input In Schedular*/
  getDisabledInput(value, callback) {
    if (callback == "schedular") {
      if (this.questionnare[1].answer[0].year1 == "") {
        this.isDisableYearOne = value;
      }
      if (this.questionnare[1].answer[0].year2 == "") {
        this.isDisableYearTwo = value;
      }

      if (this.questionnare[1].answer[0].above2Years == "") {
        this.isDisableYearAbove = value;
      }
    } else {
      if (this.questionnare[8].answer[0].year1 == "") {
        this.isLNDisableYearOne = value;
      }
      if (this.questionnare[8].answer[0].year2 == "") {
        this.isLNDisableYearTwo = value;
      }
      if (this.questionnare[8].answer[0].year3 == "") {
        this.isLNDisableYearThree = value;
      }
    }
  }

  /* Income Validation */
  incomePerc: any;
  incomeValidation = () => {
    var immediateAmt =
      this.questionnare[1].answer[0].immediately != ""
        ? this.questionnare[1].answer[0].immediately.replace(/,/g, "")
        : 0;

    // console.log(immediateAmt, "imgaa");
    if (this.userData.amount > 0) {
      var incomeAmt = this.questionnare[7].answer.replace(/,/g, "");
      var amount =
        ((parseInt(immediateAmt) + this.userData.amount) / 100) *
        this.capital_Yeild;
      // if (amount >= parseInt(incomeAmt)) {
      //   this.incomeError = false;
      // } else {
      //   this.incomeError = true;
      // }
      this.incomeError = true;
      console.log(amount, "amount");
      var isIcomePerc = (
        (parseInt(incomeAmt) /
          (parseInt(immediateAmt) + this.userData.amount)) *
        100
      ).toFixed(2);
      this.incomePerc = isIcomePerc;
      if (this.incomePerc < this.capital_Yeild) {
        this.incomeError = false;
      } else {
        this.incomeError = true;
      }
      console.log(this.incomePerc, "incomePerc");
      console.log(parseInt(incomeAmt), "incomeAmt");
    } else {
      this.toastr.info("Please enter immediate fund amount", "", {
        timeOut: 3000,
      });
      setTimeout(
        function () {
          this.questionnare[7].answer = "";
        }.bind(this),
        100
      );
    }
  };

  /* Calculate Liquidity Needs */
  liquidityNeedsWarnMsg: any;
  isLNDisableYearOne: any;
  isLNDisableYearTwo: any;
  isLNDisableYearThree: any;
  roundLiquidity(element, callback) {
    if (element.target.value) {
      var liquidity = element.target.value.includes(',') ? element.target.value.replace(/,/g, "") : element.target.value;
      // console.log(liquidity);
      var abc = Math.round(liquidity / 10000) * 10000;
      if (callback == "year1") {
        this.questionnare[8].answer[0].year1 = Number(abc).toLocaleString(
          "en-GB"
        );
      } else if (callback == "year2") {
        this.questionnare[8].answer[0].year2 = Number(abc).toLocaleString(
          "en-GB"
        );
      } else {
        this.questionnare[8].answer[0].year3 = Number(abc).toLocaleString(
          "en-GB"
        );
      }
    }
    this.calcLiquidityNeeds();
  }
  calcLiquidityNeeds() {
    var immediately =
      this.questionnare[1].answer[0].immediately == ""
        ? 0
        : this.questionnare[1].answer[0].immediately.replace(/,/g, "");
    if (this.userData.amount > 0) {
      var yearOne =
        this.questionnare[8].answer[0].year1 == ""
          ? 0
          : this.questionnare[8].answer[0].year1.replace(/,/g, "");
      var yearTwo =
        this.questionnare[8].answer[0].year2 == ""
          ? 0
          : this.questionnare[8].answer[0].year2.replace(/,/g, "");
      var yearThree =
        this.questionnare[8].answer[0].year3 == ""
          ? 0
          : this.questionnare[8].answer[0].year3.replace(/,/g, "");

      var liquidityNeeds =
        parseInt(yearOne) + parseInt(yearTwo) + parseInt(yearThree);

      this.avaliableAmt.liquidityNeedVal =
        this.userData.amount + parseInt(immediately) - liquidityNeeds;
      if (Math.sign(this.avaliableAmt.liquidityNeedVal) == -1) {
        this.avaliableAmt.liquidityNeedCheck = true;
      } else {
        this.avaliableAmt.liquidityNeedCheck = false;
      }

      if (liquidityNeeds > parseInt(immediately) + this.userData.amount) {
        if (parseInt(immediately) + this.userData.amount == yearOne) {
          this.questionnare[8].answer[0].year2 = "";
          this.questionnare[8].answer[0].year3 = "";
          this.liquidityNeedsWarnMsg = null;
        } else {
          //console.log("Schedule amount should be less than the investment..");
          this.liquidityNeedsWarnMsg =
            "Liquidity needs amount should be less than the immediate fund amount..";
        }
      } else {
        this.liquidityNeedsWarnMsg = null;
      }

      if (liquidityNeeds == parseInt(immediately) + this.userData.amount) {
        this.getDisabledInput(true, "liquidityNeeds");
      }
      if (liquidityNeeds < parseInt(immediately) + this.userData.amount) {
        this.getDisabledInput(false, "liquidityNeeds");
      }

      this.questionnare[8].answer[0].total_wealth = liquidityNeeds;
    } else {
      this.toastr.info("Please enter immediate fund amount", "", {
        timeOut: 3000,
      });
      setTimeout(
        function () {
          this.questionnare[8].answer[0].year1 = "";
          this.questionnare[8].answer[0].year2 = "";
          this.questionnare[8].answer[0].year3 = "";
        }.bind(this),
        100
      );
    }
  }

  /* Calculate Total Wealth */
  calcTotalWealth(getMdlName) {
    var totalOfOthers =
      this.questionnare[12].answer[0].realestate +
      this.questionnare[12].answer[0].privateequity +
      this.questionnare[12].answer[0].fixedincome +
      this.questionnare[12].answer[0].equities;
    if (totalOfOthers <= 100) {
      this.questionnare[12].answer[0].cash = 100 - totalOfOthers;
    } else {
      var extraVal = 0;
      if (getMdlName == "realestate") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].privateequity +
            this.questionnare[12].answer[0].fixedincome +
            this.questionnare[12].answer[0].equities);
      }
      if (getMdlName == "privateequity") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].realestate +
            this.questionnare[12].answer[0].fixedincome +
            this.questionnare[12].answer[0].equities);
      }
      if (getMdlName == "fixedincome") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].realestate +
            this.questionnare[12].answer[0].privateequity +
            this.questionnare[12].answer[0].equities);
      }
      if (getMdlName == "equities") {
        extraVal =
          100 -
          (this.questionnare[12].answer[0].realestate +
            this.questionnare[12].answer[0].privateequity +
            this.questionnare[12].answer[0].fixedincome);
      }
      setTimeout(() => {
        this.questionnare[12].answer[0][getMdlName] = extraVal;
      });
    }
  }

  wealthError: boolean;
  calcWealthPerc(event) {
    var immediateAmt =
      this.questionnare[1].answer[0].immediately != ""
        ? this.questionnare[1].answer[0].immediately.replace(/,/g, "")
        : 0;
    var isWealthAmt = (immediateAmt + this.userData.amount / 100) * event;
    if (immediateAmt + this.userData.amount >= isWealthAmt) {
      this.wealthError = false;
    } else {
      this.wealthError = true;
    }
  }

  behalfOthers(event) {
    if (event.length > 2) {
      this.questionnare[2].answer = "";
    }
  }

  /* Select Objecives */
  selectObjecive(item) {
    this.questionnare[9].answer = item.title;
    this.questionnare[9].desc = item.desc;
  }

  resetForm = () => {
    location.reload(true);
  };

  isConfAlert: boolean;
}
