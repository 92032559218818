import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { QuestionnaireFormComponent } from "./questionnaire-form/questionnaire-form.component";
import { ThankPageComponent } from "./thank-page/thank-page.component";
import { FormNotFoundComponent } from "./form-not-found/form-not-found.component";
import { ClientQstFormComponent } from './client-qst-form/client-qst-form.component';
import { RpqProspectComponent } from './rpq-prospect/rpq-prospect.component';
import { RpqClientComponent } from './rpq-client/rpq-client.component';
import { ThankyouComponent } from './thankyou/thankyou.component';

const routes: Routes = [
  { path: "", redirectTo: "/questionnaireForm", pathMatch: "full" },
  { path: "formNotFound", component: FormNotFoundComponent },
  // {
  //   path: "rpqProspect",
  //   component: QuestionnaireFormComponent 
  // },
  // {
  //   path: "rpqClient",
  //   component: ClientQstFormComponent
  // },
  {
    path: "thankYou",
    component: ThankPageComponent
  }, {
    path: "questionnaireForm",
    component: RpqProspectComponent
  }, {
    path: "clientQstForm",
    component: RpqClientComponent
  }, {
    path: "thankYouPage",
    component: ThankyouComponent
  }, 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
