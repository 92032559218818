import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuestionnaireFormComponent } from './questionnaire-form/questionnaire-form.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { HttpClientModule } from '@angular/common/http';
import { UserCredentialsComponent } from './user-credentials/user-credentials.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyFormatDirective } from './directives/currency-format.directive';
import { FormNotFoundComponent } from './form-not-found/form-not-found.component';
import { ThankPageComponent } from './thank-page/thank-page.component';
import { CamelCaseDirective } from './directives/camel-case.directive';
import { ToastrModule } from 'ngx-toastr';
import { ClientQstFormComponent } from './client-qst-form/client-qst-form.component';
import { RpqProspectComponent } from './rpq-prospect/rpq-prospect.component';
import { RpqClientComponent } from './rpq-client/rpq-client.component';
import { ThankyouComponent } from './thankyou/thankyou.component';

@NgModule({
  declarations: [
    AppComponent,
    QuestionnaireFormComponent,
    UserCredentialsComponent,
    CurrencyFormatDirective,
    FormNotFoundComponent,
    ThankPageComponent,
    CamelCaseDirective,
    ClientQstFormComponent,
    RpqProspectComponent,
    RpqClientComponent,
    ThankyouComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ModalModule.forRoot(),
    HttpClientModule,    
    BsDatepickerModule.forRoot(),
    ToastrModule.forRoot({
      positionClass: "toast-top-center",
      maxOpened: 1,
      preventDuplicates: true,
      timeOut: 10000,
    }),
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
