import { Component, OnInit } from '@angular/core';
import { AppComponent } from "../app.component";

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent implements OnInit {
  selectedLangObj: any = {};
  langJsonArrList: any = this.app.langJsonWithQuestion;
  constructor(public app: AppComponent,) {
  
   }

  ngOnInit() {
    var isLang = sessionStorage.getItem("lang");
    setTimeout(() => {
      if(isLang == 'en'){
        this.selectedLangObj = this.langJsonArrList[0];
      }
      else{
      this.selectedLangObj = this.langJsonArrList[1];
      console.log(this.selectedLangObj,"ar");
      }
    }, 500);
   
  }
  pageClose = () => {
    window.top.close();
  };
   // Language Switch
   onLangSelection(obj) {
    this.selectedLangObj = obj;
  }
}
