import {
  Component, TemplateRef, ViewChild,
  Inject,
  OnInit,
  HostListener,
  Output,
  EventEmitter,
  Input,
  ElementRef
} from '@angular/core';
import { CommonService } from "./services/common.service";
import * as  credentialData from '../../credential.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  credDataObj: any = (credentialData as any).default;
  rootURL: any = this.credDataObj.config.rootURL;
  langJsonWithQuestion: any = this.credDataObj.langJsonWithQuestion;
  isPatterns: any;
  loading: boolean;
  constructor(public commonService: CommonService) {
    this.isPatterns = this.commonService.getPatterns();
    this.loading = false;
  }

  ngOnInit() { }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  numericOnly(event): boolean {
    let patt = /^([0-9])$/;
    let result = patt.test(event.key);
    return result;
  }

  nearestFiftyRounding(num) {
    let num1 = num.toString().replace(/,/g, "");
    let result = Math.round(num1 / 50) * 50;
    console.log(result);
    return result;
  }
  /*Show Loader*/
  getLoader(value) {
    this.loading = value;
  }
}
