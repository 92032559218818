import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-thank-page",
  templateUrl: "./thank-page.component.html",
  styleUrls: ["./thank-page.component.scss"]
})
export class ThankPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {
    // setTimeout(() => {
    //   this.pageClose();
    // }, 10000);
  }

  pageClose = () => {
    window.top.close();
  };
}
