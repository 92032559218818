import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';

@Directive({
  selector: '[appCurrencyFormat]'
})
export class CurrencyFormatDirective {

  private el: HTMLInputElement;
  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }
  @HostListener('input', ['$event'])
  onInput($event) {
    //let newValue = this.el.value;
    let newValue = this.elementRef.nativeElement.value;
    var isValue;
    const pattern = /^\d+(,\d+)*$/;
    let patternValidation = newValue.match(pattern); //replace here with your regexp
    if (patternValidation) {
      //keep input
      var getParse = isNaN(newValue) ? newValue.replace(/,/g, "") : newValue;
      var num = Number(getParse).toLocaleString("en-GB");
      isValue = num;
    } else {
      var getParse = newValue.replace(/[^\d]/g, '');
      var number = getParse.replace(/,/g, "");
      isValue = Number(number).toLocaleString("en-GB");
    }
    this.el.value = isValue != 0 ? isValue : "";
  }

}
